import moment from "moment";

export const TOAST = "toast";
export const SHOW_CONFIRM_BOX = "confirm";
export const MODAL_CONFIRMED = "confirmed";
export const SELECTED_LOCATION = "selectelocation";
export const DATE_RANGE = "daterange";
export const TODAY = "Today";
export const YESTERDAY = "Yesterday";
export const LAST_7_DAYS = "Last 7 Days";
export const LAST_14_DAYS = "Last 14 Days";
export const LAST_28_DAYS = "Last 28 Days";
export const LAST_1_MONTH = "Last 1 Month";
export const LAST_3_MONTHS = "Last 3 Months";
export const LAST_6_MONTHS = "Last 6 Months";
export const LAST_1_YEAR = "Last 1 Year";

export const JS_DATE_FORMAT = "YYYY-MM-DD";
export const today = moment().format(JS_DATE_FORMAT);
export const yesterday = moment().subtract(1, "days").format(JS_DATE_FORMAT);

export function dateRangePickerDateOptions(options = {}) {
  const endDate =  today ;
  return [
    { label: TODAY, range: [today, today] },
    { label: YESTERDAY, range: [yesterday, yesterday] },
    {
      label: LAST_7_DAYS,
      range: [moment().subtract(7, "days").format(JS_DATE_FORMAT), endDate],
    },
    {
      label: LAST_14_DAYS,
      range: [moment().subtract(14, "days").format(JS_DATE_FORMAT), endDate],
    },
    {
      label: LAST_28_DAYS,
      range: [moment().subtract(28, "days").format(JS_DATE_FORMAT), endDate],
    },
    {
      label: LAST_1_MONTH,
      range: [moment().subtract(1, "months").format(JS_DATE_FORMAT), endDate],
    },
    {
      label: LAST_3_MONTHS,
      range: [moment().subtract(3, "months").format(JS_DATE_FORMAT), endDate],
    },
    {
      label: LAST_6_MONTHS,
      range: [moment().subtract(6, "months").format(JS_DATE_FORMAT), endDate],
    },
    {
      label: LAST_1_YEAR,
      range: [moment().subtract(1, "year").format(JS_DATE_FORMAT), endDate],
    },
  ];
}
